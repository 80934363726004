<template>
  <div>
    <LoaderComponent v-if="showLoader"/>
    <nav :class="navClass">
      <router-link to="/">
        <img alt="Grube Studios Logo" src="./assets/gs-logo.png" />
      </router-link>
      <button v-if="navClass == 'mobile'" @click="toggleMobileHidden">
        <font-awesome-icon icon="fa-solid fa-bars" />
      </button>
      <ul :class="{hidden: mobileHidden}">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/games">Games</router-link>
        </li>
        <li v-if="!this.$store.state.authenticated">
          <router-link to="/login">Login/Signup</router-link>
        </li>
        <li v-if="this.$store.state.authenticated">
          <router-link to="/account">Account</router-link>
        </li>
        <li v-if="this.$store.state.authenticated">
          <a href="#" v-on:click="doLogout">Logout</a>
        </li>
      </ul>
    </nav>

    <router-view class="main-container" />
    <footer>
      Copyright GrubeStudios. All Rights Reserved.
    </footer>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import LoaderComponent from '@/components/Loader'

export default {
  name: 'AppView',
  components: {
    FontAwesomeIcon,
    LoaderComponent
  },
  data(){
    return {
      navClass: 'desktop'
    }
  },
  created(){
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
    if (this.$store.state.games <= 0) {
      this.$store.dispatch('getGames');
    }
  },
  computed: {
    mobileHidden() {
      return this.$store.state.mobileHidden;
    },
    showLoader() {
      return this.$store.state.showLoader;
    }
  },
  methods: {
    async doLogout(e){
      e.preventDefault();
      this.$store.commit('updateState', false)
      this.$router.push({ name: 'login' })
    },
    handleResize() {
      this.navClass = window.matchMedia('(max-width: 640px)').matches ? 'mobile' : 'desktop'
    },
    toggleMobileHidden(){
      this.$store.commit('toggleMobileHidden');
    }
  }
}

</script>

<style lang="scss">
#app {
  font-family: 'Oswald', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.main-container {
  min-height:calc(100vh - 220px);
}

nav.desktop {
  position: fixed;
  width:100%;
  background: $brandGreen;
  padding: 10px;
  display: flex;
  align-items: center;
  z-index: 100;

  img {
    margin-left:10px;
    width:200px;
    height:45px;
  }

  ul {
    width:100%;
    margin-right:10px;
    text-align: right;

    li {
      display:inline-block;
      padding-right:10px;

      a {
        color:white;
        font-size:20px;

        &.router-link-exact-active,
        &:hover {
            color: $brandGold;
          }
      }
    }
  }
}

nav.mobile {
  position: fixed;
  width: 100%;
  background: $brandGreen;
  padding: 0px;
  z-index: 100;
  text-align: left;

  button {
    background: $brandGreen;
    color: $brandGold;
    position: absolute;
    top:10px;
    right:10px;
    width:50px;
    margin:0;

    &:hover,
    &:active,
    &:focus {
      background: $brandGold;
      color: $brandGreenDark;
    }
  }

  a {

    img {
      padding: 10px;
      margin: 0;
      width: 200px;
    }
  }

  ul {
    width:100%;
    margin:0;
    background-color: $brandGreenDark;
    padding:0;

    &.hidden {
      display: none;
    }

    li {
      text-align: center;
      list-style-type: none;
      padding:10px;
    }
  }
}

footer {
  background:$brandGreen;
  color:$brandGold;
  padding:40px 0px;
  margin-top:60px;
}
</style>
