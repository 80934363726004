<template>
    <div class="account">
        <h1>Your Account</h1>
        <div class="account-info">
            <p class="word-wrap-word">Email: {{user.email}}</p>
            <p class="word-wrap-word">Display Name: {{user.displayName}}</p>
            <button @click="toggleShowChangePassword" v-if="!showChangePassword">Change Password</button>

            <form @submit="changePassword" v-if="showChangePassword">
                <h2>Change Password</h2>
                <div>
                    <label>Old Password</label>
                    <input type="password" v-model="oldPassword">
                    <p class="error" v-if="errors && errors.oldPassword">{{errors.oldPassword}}</p>
                </div>
                <div>
                    <label>New Password</label>
                    <p class="small-note">Password must contain at least 8 characters, 1 symbol, 1 uppercase and 1 lowercase.</p>
                    <input type="password" v-model="newPassword">
                    <p class="error" v-if="errors && errors.newPassword">{{errors.newPassword}}</p>
                </div>
                <div>
                    <label>Confirm New Password</label>
                    <input type="password" v-model="newPasswordConfirm">
                    <p class="error" v-if="errors && errors.newPasswordConfirm">{{errors.newPasswordConfirm}}</p>
                </div>
                <button>Change Password</button>
            </form>
        </div>

        <h1>Games Played</h1>
        <div class="games-list">
            <div v-if="user.games.length > 0">
                <div class="game-card" v-for="game in this.$store.state.user.games" :key="game.title">
                    <div class="flex">
                        <div>
                            <img :alt="game.title + ' Logo'" :src="'/images/' + getGameLogoByTitle(game.title)" />
                        </div>
                        <div>
                            <p><span>{{game.title}}</span></p>
                            <p>High Score: <span>{{game.highscore}}</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                No games played.
            </div>
        </div>
    </div>
</template>

<script>
import GameService from '@/services/GameService';
import UserService from '@/services/UserService';
export default {
    name: 'AccountView',
    data() {
        return {
            user: this.$store.state.user,
            oldPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
            showChangePassword: false,
            errors: {
                oldPassword: '',
                newPassword: '',
                newPasswordConfirm: ''
            }
        }
    },
    methods: {
        getGameLogoByTitle(title) {
            return GameService.getGameLogoByTitle(title, this.$store.state.games);
        },
        changePassword(e){
            e.preventDefault();
            this.$store.commit('showLoader', true);
            this.resetErrors();
            let isError = false;
            if(this.oldPassword.replace(/\s+/g, '') == '') {
                isError = true;
                this.errors.oldPassword = 'Old Password is required.';
            }
            if (this.newPassword.replace(/\s+/g, '') == '') {
                isError = true;
                this.errors.newPassword = 'New Password is required.';
            }
            if (this.newPasswordConfirm.replace(/\s+/g, '') == '') {
                isError = true;
                this.errors.newPasswordConfirm = 'Confirm New Password is required.';
            }
            if (this.newPassword != this.newPasswordConfirm) {
                isError = true;
                this.errors.newPassword = 'Passwords do not match.';
            }

            if(!isError) {
                UserService.ChangePassword(this.newPassword, this.oldPassword)
                .then(res => {
                    this.$store.commit('showLoader', false);
                    if(res.data.updated) {
                        this.oldPassword = '';
                        this.newPassword = '';
                        this.newPasswordConfirm = '';
                        this.toggleShowChangePassword();
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.$store.commit('showLoader', false);
                    this.errors = err.response.data;
                })
            } else {
                this.$store.commit('showLoader', false);
            }
        },
        resetErrors() {
            let _this = this;
            Object.keys(this.errors).forEach(errorKey => {
                _this.errors[errorKey] = '';
            })
        },
        toggleShowChangePassword() {
            this.showChangePassword = !this.showChangePassword;
        }
    }
}

</script>

<style lang="scss" scoped>
.account{
    font-family: 'Oswald', sans-serif;
    .account-info {
        background:$brandGray;
        max-width: 350px;
        margin: 10px auto;
        padding:20px;
        border-radius: 10px;

        form {
            h2 {
                color:$brandGold;
            }
        }
    }

    .games-list {
        margin:10px;
    }
}
</style>