<template>
    <div class="games">
        <div v-if="games.length > 0">
            <h1>Mobile Games</h1>
            <div class="grid-3">
                <GameCard v-for="(game, index) in mobileGames" :key="index" :game="game" />
            </div>
            <h1>PowerPoint Games</h1>
            <div class="grid-3">
                <GameCard v-for="(game, index) in powerpointGames" :key="index" :game="game" />
            </div>
        </div>
    </div>
</template>

<script>
    import GameCard from '@/components/GameCard'
import GameService from '@/services/GameService';
    export default {
        name: 'GameView',
        components: {GameCard},
        data() {
            return {
                games: this.$store.state.games
            }
        },
        created() {
            this.updateAllGames();
        },
        methods: {
            async updateAllGames() {
                await GameService.all()
                .then(res => {
                    this.$store.commit('updateGames', res);
                })
                .catch(() => {})
            }
        },
        computed: {
            mobileGames() {
                return this.$store.state.games.filter((game) => { 
                    if(game.type == 'mobile') return game; 
                });
            },
            powerpointGames() {
                return this.$store.state.games.filter((game) => {
                    if (game.type == 'powerpoint') return game;
                });
            }

        }
    }
</script>

<style lang="scss" scoped>
.games {
    width:80%;
    margin:0 auto;
    text-align: left;

    .grid-3 {

        @media screen and (max-width:1100px) {
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width:640px) {
            grid-template-columns: 1fr;
        }
    }
}
</style>