<template>
    <div class="game-card">
        <div class="flex info">
            <div class="game-image">
                <img :alt="game.title + ' Logo'" :src="'/images/' + game.logoUrl" />
            </div>
            <div>
                <p class="word-wrap-word capitalize"><span>{{ game.title }}</span></p>
                <p class="word-wrap-word capitalize">Genre: <span>{{ game.genre }}</span></p>
            </div>
        </div>
        <router-link class="button" :to="'/games/' + game.slug">View Game Page</router-link>
    
        <a target="_blank" v-if="game.marketplaces.includes('google')" class="button"
            href="https://play.google.com/store/games">Google Play Store</a>
        <a target="_blank" v-if="game.marketplaces.includes('etsy')" class="button"
            href="https://www.etsy.com/shop/GrubeStudios">Etsy Store</a>
    </div>
</template>

<script>
    export default {
        name: 'GameCard',
        props: ['game']
    }
</script>

<style lang="scss" scoped>
.game-card {
    margin: 10px!important;

    .game-image {
        min-height:110px;
    }
}
</style>