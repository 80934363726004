import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faArrowLeftLong, faBars } from '@fortawesome/free-solid-svg-icons'

library.add( faArrowLeftLong, faBars );

createApp(App).use(store).use(router).mount('#app')
