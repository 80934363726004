export default class Game {
    title = '';
    slug = '';
    description = '';
    type = '';
    genre = '';
    logoUrl = '';
    headerUrl = '';
    images = [];
    leaderboard = [];
    marketplaces = [];
    createdAt = new Date();
}