<template>
    <div class="leaderboard">
        <h1>{{ game.title }} Leaderboard</h1>

        <div>
            <router-link :to="'/games/' + game.slug"><font-awesome-icon icon="fa-solid fa-arrow-left-long" /> Back to {{game.title}} Page</router-link>
        </div>

        <div class="list" v-if="game.leaderboard.length > 0">
            <ul>
                <li v-for="(leader, index) in game.leaderboard" :key="index" >
                    <div class="flex">
                        <div class="position">
                            {{index + 1}}
                        </div>
                        <div class="username">
                            {{leader.username}}
                        </div>
                        <div class="score">
                            {{leader.score}}
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="no-entries" v-else>
            No Leaderboard Entries
        </div>
    </div>
</template>

<script>
    import GameService from '@/services/GameService';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import Game from '@/classes/Game';
    
    export default {
        name:'LeaderboardView',
        components : {
            FontAwesomeIcon
        },
        data(){
            return {
                slug: this.$route.params.slug,
                game: new Game()
            }
        },
        created() {
            this.updateLeaderboard()
            this.getGame();
        },
        methods: {
            async updateLeaderboard(){
                await GameService.leaderboard(this.slug)
                .then(res => {
                    this.$store.commit('updateLeaderboard', { board: res.data.collection, slug: this.slug});
                })
                .catch(err => {
                    console.log(err);
                })
            },
            getGame() {
                this.game = GameService.single(this.slug, this.$store.state.games)
            }
        }
    }
</script>

<style lang="scss" scoped>
.leaderboard {

    .no-entries {
        background:$brandGray;
        max-width:400px;
        color:$brandGrayDark;
        padding:15px;
        margin: 0 auto;
        border-radius: 10px;
        font-style: italic;
    }

    .list {
        margin:0 auto;
        text-align: left;
        max-width:800px;

        ul {
            margin-left:20px;
            padding:0;

            li {
                border-radius: 10px;
                margin:10px;
                list-style-type: none;

                .flex {
                    align-items: center;
                    font-size:1.5em;
                }

                .position {
                    width:10%;
                    overflow: hidden;
                    background: $brandGreenDark;
                    color: white;
                    padding:15px;
                    border-radius: 10px;
                    text-align: center;
                    margin-right:10px;
                }

                .username {
                    width:66.666%;
                    overflow: hidden;
                    background: $brandGreen;
                    color: $brandGold;
                    padding:15px;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    text-align: left;
                }

                .score {
                    width:33.3333%;
                    overflow: hidden;
                    background-color: $brandGold;
                    color:$brandGreen;
                    padding:15px;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    text-align: center;
                }

            }
        }
    }
}
</style>