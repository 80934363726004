import { createRouter, createWebHistory } from 'vue-router'
import UserService from '@/services/UserService'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/account/Login'
import RegisterView from '../views/account/Register'
import AccountView from '../views/account/Show'
import AllGamesView from '../views/games/All'
import ShowGameView from '../views/games/Show'
import LeaderboardView from '../views/Leaderboard'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView
  },
  {
    path: '/games',
    name: 'games',
    component: AllGamesView
  },
  {
    path: '/games/:slug',
    name: 'single-game',
    component: ShowGameView
  },
  {
    path: '/leaderboard/:slug',
    name: 'leaderboard',
    props: true,
    component: LeaderboardView
  }
]

const router = createRouter({
  scrollBehavior() {
    return { top: 0 }
  },
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  store.commit('hideMobileNav');
  const canAccess = await canUserAccess(to, from)
  if (!canAccess && to.name !== 'login') return '/login'
  if (!canAccess && to.name === 'login') return '/'
});

async function checkAuthentication() {
  return await UserService.verify()
    .then((res) => {
      store.commit('updateState', res.data);
      return res.data.authenticated;
    })
    .catch(() => {
      store.commit('updateState', false);
      return false;
    })
}

async function canUserAccess(to) {
  switch(to.name) {
    case 'home':
      return true;
    case 'games':
      return true;
    case 'single-game':
      return true;
    case 'leaderboard':
      return true;
    case 'login':
      if (store.state.authenticated) {
        return false;
      }
      return true;
    case 'register':
      if (store.state.authenticated) {
        return false;
      }
      return true;
    case 'account':
      if (await checkAuthentication()) {
        return true;
      }
      return false;
    default:
      return false;
  }
}



export default router
