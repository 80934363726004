import { createStore } from 'vuex'
import User from '@/classes/User';
import GameService from '@/services/GameService';
import createPersistedState from "vuex-persistedstate";
import SecureLS from 'secure-ls'

const ls = new SecureLS({isCompression: false});

export default createStore({
  state: {
    authenticated: false,
    token: '',
    expires: '',
    user: new User(),
    games: [],
    mobileHidden: true,
    showLoader: false
  },
  plugins: [
    createPersistedState({
      key: 'gstudios',
      paths: ['authenticated', 'user', 'games', 'token', 'expires'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  getters: {
  },
  mutations: {
    updateState(state, data){
      if(data){
        state.authenticated = data.authenticated;
        state.user.email = data.email;
        state.user.displayName = data.displayName;
        state.user.games = data.games
      } else {
        state.authenticated = false;
        state.user = new User();
        state.token = '';
        state.expires = '';
      }
    },
    setToken(state, data){
      state.token = data.token;
      state.expires = data.expires;
    },
    unsetToken(state) {
      state.toke = '';
      state.expires = '';
    },
    showLoader(state, data) {
      if(data) {
        state.showLoader = true;
      } else {
        state.showLoader = false;
      }
    },
    updateLeaderboard(state, data) {
      state.games.forEach(game => {
        if(game.slug == data.slug) {
          game.leaderboard = data.board;
        }
      })
    },
    updateGames(state, games) {
      state.games = games;
    },
    toggleMobileHidden(state){
      state.mobileHidden = !state.mobileHidden;
    },
    hideMobileNav(state) {
      state.mobileHidden = true;
    }
  },
  actions: {
    async getGames(context) {
      await GameService.all()
      .then(res => {
        context.commit('updateGames', res)
      })
      .catch((err) => {
        console.log(err);
      })
    }
  },
  modules: {
  }
})
