<template>
    <div class="login">
        <h1>Login</h1>
        <div>
            <p>Don't have an Account? <router-link to="/register">Register</router-link>
            </p>
        </div>
        <form @submit="doLogin">
            <div>
                <label for="email">Email</label>
                <input type="email" name="email" v-model="email">
            </div>
            <div>
                <label for="password">Password</label>
                <input type="password" name="password" v-model="password">
            </div>
            <p class="error" v-if="error">{{error}}</p>
            <button>Login</button>
        </form>
    </div>
</template>

<script>

import UserService from '@/services/UserService'

export default {
    name: 'LoginView',
    components: {},
    data() {
        return {
            email: '',
            password: '',
            error: ''
        }
    },
    methods: {
        async doLogin(e) {
            e.preventDefault();
            this.$store.commit('showLoader', true);
            this.error = '';
            await UserService.login({
                username: this.email,
                password: this.password
            })
            .then((res) => {
                if(res.data.token) {
                    let obj = {
                        token: res.data.token,
                        expires: res.data.expires
                    };
                    this.$store.commit('setToken', obj);
                }
                this.$store.commit('showLoader', false);
                this.$router.push({ name: 'account' })
            })
            .catch((err) => {
                this.$store.commit('showLoader', false);
                if(err.response.status == 401){
                    this.error = err.response.data;
                } else {
                    this.error = 'System Error. Please contact us.';
                }
            })
        }
    }
}

</script>

<style lang="scss" scoped>
button {
    margin: 30px auto 10px auto;
}
</style>