<template>
    <div class="show-game">

        <div class="game-header">
            <img :src="'/images/' + game.headerUrl" :alt="game.title + ' Header'">
        </div>

        <div v-if="game.marketplaces.length > 0">
            <a v-if="game.marketplaces.includes('google')" target="_blank" href="https://play.google.com/store/games" class="button button-small">Get on Google Play</a>
            <a target="_blank" v-if="game.marketplaces.includes('etsy')" class="button button-small" href="https://www.etsy.com/shop/GrubeStudios">Etsy Store</a>
        </div>

        <h1>Play {{game.title}} Now!</h1>
        <div class="game-info">
            {{game.description}}
            <router-link v-if="game.leaderboard.length > 0" class="button button-small" :to="'/leaderboard/' + game.slug">View Leaderboard</router-link>
        </div>

        <div class="flex">
            <div v-for="(image, index) in game.images" :key="index">
                <img :src="'/images/' + image" alt="Gameplay" />
            </div>
        </div>
    </div>
</template>

<script>
    import GameService from '@/services/GameService';
    import Game from '@/classes/Game'
    
    export default {
        name: 'ShowGameView',
        data(){
            return {
                slug: this.$route.params.slug,
                game: new Game()
            }
        },
        created() {
            this.getGame()
        },
        methods: {
            getGame() {
                this.game = GameService.single(this.slug, this.$store.state.games)
            }
        }
    }
</script>

<style lang="scss" scoped>
.show-game {

    .game-header {
        position: relative;
        background: linear-gradient(0deg, rgba(0 0 0 / 0%), rgba(0 0 0 / 60%)), url('@/assets/game-header-bg.png') repeat top left;

        img {
            width: 100%;
            max-width: 1000px;
        }
    }

    .game-info {
        padding: 0% 5% 5% 5%;
    }

    .flex {
        padding:0 5em;

        > div {
            margin:10px;
        }

        img {
            flex:1;
            display:block;
            width: 100%;
        }
    }
}
</style>