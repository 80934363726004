/* eslint-disable */
import axios from 'axios';
import store from '../store';

const url = '/api/users';
const verifyUrl = '/api/auth';
const loginUrl = '/api/auth/login';
const changePassUrl = '/api/users';

class UserService {

    //Create Account
    static create(obj) {
        return axios.post(url, {
            email: obj.email,
            displayName: obj.displayName,
            password: obj.password
        });
    }

    static ChangePassword(newPassword, oldPassword) {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.put(changePassUrl, { newPassword: newPassword, oldPassword: oldPassword }, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    static verify() {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.get(verifyUrl, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    //Login
    static login(email, password) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(loginUrl, email, password);
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

}

export default UserService;