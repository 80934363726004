<template>
  <div class="home">
    <div class="featured-game">
      <div class="logo">
        <img :src="'./images/VirtualPubTrivia/VirtualPubTrivia.png'" alt="Virtual Pub Trivia">
      </div>
      <div class="info">
        <p>
          Pub Trivia is a PowerPoint based game filled with animations, music and fun! Easy to play, just open the file in 
          PowerPoint and begin the presentation. If you are playing on Zoom, Google Meets, etc. just share your screen.
        </p>
        <p class="category-title">Categories</p>
        <ul>
          <li>World Geography</li>
          <li>Sports</li>
          <li>Music</li>
          <li>Food & Drink</li>
          <li>History</li>
          <li>Science</li>
          <li>Nature</li>
        </ul>
        <a target="_blank" href="https://www.etsy.com/shop/GrubeStudios">Available on Etsy</a>
      </div>
    </div>

    <div class="upcoming-now-available flex">
      <div class="coming-soon">
        <h1>Coming Soon</h1>
        <div class="game" @click="routeTo('ChefToss')">
          <img src="/images/ChefToss/ChefToss.png" alt="Chef Toss Coming Soon">
          <p>Chef Toss is a quick paced mobile game that will leave you coming back for more. It is in development, check back for more information on the release date.</p>
        </div>
      </div>
      <div class="now-available">
        <h1>Now Available</h1>
        <div class="flex">
          <div class="game" @click="routeTo('VirtualPubTrivia')">
            <div>
              <img src="/images/VirtualPubTrivia/VirtualPubTrivia.png" alt="Virtual Pub Trivia"/>
              <p>Virtual Pub Trivia Now Available on Etsy</p>
            </div>
          </div>
          <div class="game" @click="routeTo('UltimateAmericanTrivia')">
            <div>
              <img src="/images/UltimateAmericanTrivia/UltimateAmericanTrivia.png" alt="Ultimate American Trivia" />
              <p>Ultimate American Trivia Now Available on Etsy</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>



export default {
  name: 'HomeView',
  methods: {
    routeTo(slug) {
      this.$router.push({ name: 'single-game', params: {slug: slug} })
    }
  }
}

</script>

<style lang="scss" scoped>
.home {

  .featured-game {
    font-family: 'Quicksand', sans-serif;
    color:white;
    width:100%;
    min-height:450px;
    background:url('@/assets/FeaturedGameBG.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;

    @media screen and (max-width:800px) {
      flex-direction: column;
    }

    .logo {
      flex:1;

      img {
        max-width:500px;

        @media screen and (max-width:800px) {
          width:100%;
        }
      }
    }

    .info {
      flex:1;
      margin-right:10em;

      @media screen and (max-width:1400px) {
        margin-right: 5em;
        font-size:14px;
      }

      @media screen and (max-width:800px) {
        margin:20px;
      }

      .category-title {
        margin-top:30px;
        text-transform: uppercase;
        color:#cf53e7;
        font-size:1.2em;
      }

      ul {
        padding:0;
        margin-bottom:15px;
        li {
          list-style-type: none;
          display: inline-block;
          color:#ffe778;
          margin-bottom: 15px;

          &:not(:last-of-type) {
            &:after {
              content: '|';
              color:white;
              margin-left:10px;
              margin-right:10px;
            }
          }
        }
      }

      a {
        font-family: 'Quicksand', sans-serif;
        background:#d255e750;
        color:#5bdcf2;
        display: inline-block;
        border:3px solid #d255e7;
        padding:10px 20px;
        border-radius: 10px;
        transition: all 0.3s ease-in;
        text-transform: uppercase;

        &:hover {
          background:#ff84f750;
          color:#8dfdff;
          border:3px solid #ff84f7;
          box-shadow: 0px 0px 20px 3px rgba(255, 132, 247, 0.34);
        }
      }
    }
  }

  .upcoming-now-available {
    align-content: center;
    margin:2em;

    @media screen and (max-width:640px) {
      flex-direction: column;
    }

    .coming-soon {
      flex:1;
      margin-right:30px;

      @media screen and (max-width:640px) {
          margin-right: 0px;
      }

      .game {
        position: relative;
        height:350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow:hidden;
        background:url('@/assets/ComingSoonBG.jpg');
        background-position: center center;
        background-size: cover;

        img {
          max-width:500px;
          width:100%;
          padding:30px;
          transition: all 0.3s ease-in;
        }
      }
    }

    .now-available {
      flex:1;

      .flex {
        flex-direction: column;
      }

      .game {
        height:165px;
        display: flex;
        align-items: center;

        > div {
          width:100%;
        }

        &:first-of-type {
          background: url('@/assets/FeaturedGameBG.jpg');
          background-size: cover;
          background-repeat: no-repeat;
        }

        &:last-of-type {
          background: url('@/assets/SecondAvailableGameBG.jpg');
          background-size: cover;
          background-repeat: repeat;
          background-position: center center;

          img {
            max-width:350px;
          }
        }
        
        img {
          flex:1;
          max-width: 230px;
          width: 100%;
          transition: all 0.3s ease-in;
        }
      }
    }

    .game {
      cursor: pointer;
      background: white;
      border-radius: 10px;
      margin-bottom:20px;
      overflow: hidden;
      position: relative;

      img {
        max-width: 100%;
        height: auto;
      }

      p {
          margin:0;
          width:100%;
          color:black;
          padding:10px;
          background:rgba(255,255,255, 0.8);
          position:absolute;
          bottom:-500px;
          transition: all 0.5s ease-in;
      }

      &:hover,
      &:focus,
      &:active {

        box-shadow: rgba(0,0,0,0.5) 0px 0px 10px 3px;

        p {
          left:-10px;
          bottom: 0px;
        }

        img {
          height: 60%;
          transform: scaleX(0.9);
        }

      }
    }
  }
}
</style>