/* eslint-disable */
import axios from 'axios';
import Game from '@/classes/Game';

const url = '/api/games';
const leaderboardUrl = '/api/leaderboard/';

class GameService {

    //Get All Games
    static async all() {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(url);
                const data = res.data;
                resolve(
                    data.map(game => ({
                        ...game
                    }))
                );
            } catch(err) {
                reject(err);
            }
        });
    }

    //Get a game
    static single(slug, games) {
        let found = new Game();
        if(games.length > 0) {
            games.forEach(game => {
                if (game.slug == slug) {
                    found = game;
                    return;
                }
            });
        }
        return found;
    }

    static leaderboard(slug) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(leaderboardUrl + '/' + slug);
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    static getGameLogoByTitle(title, games) {
        let logoUrl = '';
        if (games.length > 0) {
            games.forEach(game => {
                if (game.title == title) {
                    logoUrl = game.logoUrl;
                    return;
                }
            });
        }
        return logoUrl;
    }

}

export default GameService;