<template>
    <div class="register">
        <h1>Register</h1>
        <div>
            <p>Already have an Account? <router-link to="/login">Login</router-link></p>
        </div>

        <form @submit="createUser">
            <div>
                <label for="email">Email</label>
                <input type="email" name="email" v-model="email">
                <p class="error" v-if="errors.email">{{errors.email}}</p>
            </div>
            <div>
                <label for="displayName">Display Name</label>
                <input type="text" name="displayName" v-model="displayName">
                <p class="error" v-if="errors.displayName">{{errors.displayName}}</p>
            </div>
            <div>
                <label for="password">Password</label>
                <p class="small-note">Password must contain at least 8 characters, 1 symbol, 1 uppercase and 1 lowercase.</p>
                <input type="password" name="password" v-model="password">
                <p class="error" v-if="errors.password">{{errors.password}}</p>
            </div>
            <div>
                <label for="confirm-password">Confirm Password</label>
                <input type="password" name="password" v-model="confirmPass">
            </div>
            <div>
                <button>Create Account</button>
            </div>
        </form>
        <div class="error" v-if="errors.other">{{ errors.other }}</div>
    </div>
</template>

<script>

import UserService from '@/services/UserService'

export default {
    name: 'RegisterView',
    data() {
        return {
            users: [],
            email: '',
            displayName: '',
            password: '',
            confirmPass: '',
            errors: {
                email: '',
                displayName: '',
                password:'',
                other: ''
            }
        }
    },
    async created() {
        
    },
    methods: {
        async createUser(e) {
            e.preventDefault();
            this.$store.commit('showLoader', true);
            this.resetErrors()
            if((this.password.includes(' ') || this.confirmPass.includes(' ')) || this.password !== this.confirmPass) {
                this.errors.password = 'Passwords do not match.';
                this.$store.commit('showLoader', false);
                return;
            }
            await UserService.create({
                email: this.email,
                displayName: this.displayName,
                password: this.password
            })
            .then((res) => {
                this.$store.commit('showLoader', false);
                console.log(res);
                if(res.status == 201) {
                    if (res.data.token) {
                        let obj = {
                            token: res.data.token,
                            expires: res.data.expires
                        };
                        this.$store.commit('setToken', obj);
                    }
                    this.$router.push({ name: 'account' })
                }
            })
            .catch(err => {
                this.$store.commit('showLoader', false);
                console.log(err);
                let _this = this;
                Object.keys(err.response.data).forEach( errorKey => {
                    _this.errors[errorKey] = err.response.data[errorKey];
                })
            })
        },
        resetErrors() {
            let _this = this;
            Object.keys(this.errors).forEach(errorKey => {
                _this.errors[errorKey] = '';
            })
        }
    }
}

</script>

<style lang="scss" scoped>
button {
    margin: 30px auto 10px auto;
}
</style>