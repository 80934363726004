<template>
    <div class="loader">
        <div>
            <div class="box"></div>
            <img src="@/assets/gs-emblem.png" alt="Grube Studio Emblem">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoaderComponent'
    }
</script>

<style lang="scss" scoped>
.loader {
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index:101;
    background:rgba(157 143 116 / 50%);

    .box {
        position:absolute;
        top:50%;
        left:50%;
        margin-top:-75px;
        margin-left:-75px;
        width:150px;
        height:150px;
        background:$brandGreen;
        transform: rotate(45deg);
        animation-name: loading-bounce;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
    }

    img {
        display:block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -60px;
        margin-left: -50px;
        width: 100px;
        height:120px;
        animation-name: loading-spinner;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
    }

}
</style>